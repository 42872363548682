import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Img from "gatsby-image";
import * as styles from "../styles/offerCarusele.module.css";
import { Link } from "gatsby";
import _ from "lodash";
import OfferCaruseleContent from "../assets/OfferCaruseleContent";

const OfferCarusele = (props) => {
 const responsive = {
  desktop: {
   breakpoint: { max: 3000, min: 1200 },
   items: props.desktop,
   slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
   breakpoint: { max: 1200, min: 600 },
   items: props.tablet,
   slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
   breakpoint: { max: 600, min: 320 },
   items: props.mobile,
   slidesToSlide: 1, // optional, default to 1.
  },
 };

 const data = OfferCaruseleContent();

 const trimm = (text) => {
  if (text.length > 60) {
   let newText = text.slice(0, 60);
   return newText + "...";
  }

  return text;
 };

 const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
   onMove,
   carouselState: { currentSlide, deviceType },
  } = rest;

  return <button onClick={() => onClick()} className={styles.rightArrow} />;
 };
 const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
   onMove,
   carouselState: { currentSlide, deviceType },
  } = rest;

  return <button onClick={() => onClick()} className={styles.leftArrow} />;
 };

 return (
  <section className={styles.carouselSection}>
   <Carousel
    autoPlaySpeed={2500}
    ssr={true}
    autoPlay={true}
    infinite={true}
    responsive={responsive}
    customRightArrow={<CustomRightArrow />}
    customLeftArrow={<CustomLeftArrow />}
   >
    {data.map((item, index) => (
     <Link key={_.uniqueId("carusele_")} to={item.link}>
      <div className={styles.background}>
       <Img objectFit="cover" fluid={item.img} alt={item.alt} />
       <div className={styles.infoWrapper}>
        <div>
         <h3>{item.title}</h3>
        </div>
        <div className={styles.descWrapper}>
         <p>{trimm(item.desc)}</p>
        </div>
       </div>
      </div>
     </Link>
    ))}
   </Carousel>
  </section>
 );
};

export default OfferCarusele;
