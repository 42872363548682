import { useStaticQuery, graphql } from "gatsby";

const OfferCaruseleContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { extension: { eq: "png" }, relativeDirectory: { eq: "offer" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = [
    {
      img: data.allFile.edges[0].node.childImageSharp.fluid,
      title: "Przykładowa nazwa",
      alt: "Przykładowa nazwa",
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing",
      link: "/co-robimy",
    },
    {
      img: data.allFile.edges[1].node.childImageSharp.fluid,
      title: "Przykładowa nazwa",
      alt: "Przykładowa nazwa",
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing",
      link: "/co-robimy",
    },
    {
      img: data.allFile.edges[2].node.childImageSharp.fluid,
      title: "Przykładowa nazwa",
      alt: "Przykładowa nazwa",
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing",
      link: "/co-robimy",
    },
    {
      img: data.allFile.edges[3].node.childImageSharp.fluid,
      title: "Przykładowa nazwa",
      alt: "Przykładowa nazwa",
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing",
      link: "/co-robimy",
    },
  ];

  return content;
};

export default OfferCaruseleContent;
