import React from "react";
import * as styles from "../styles/offer.module.css";

const OfferComponent = () => {
 return (
  <section id="co-robimy" className="section-background">
   <div className={styles.sectionContainer}>
    <div className={styles.titleWrapper}>
     <h2>Co robimy</h2>
    </div>
    <div className={styles.descWrapper}>
     <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. Lorem ipsum dolor sit amet, consetetur sadipscing
     </p>
    </div>
   </div>
  </section>
 );
};

export default OfferComponent;
